import React from 'react';
import Header from '../components/sections/Header';
import Footer from '../components/sections/Footer';
import Layout from '../components/common/Layout';
import Navbar from '../components/common/Navbar';
import HompageMainContent from '../components/sections/HompageMainContent';
import '../styles/custom.css';

const IndexPage = () => (
    <Layout>
        <Navbar/>
        <Header/>
        <HompageMainContent/>
        <Footer/>
    </Layout>
);

export default IndexPage;
