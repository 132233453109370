import React from 'react';
import styled from 'styled-components';
import {graphql, Link, StaticQuery} from 'gatsby';
import BackgroundImage from 'gatsby-background-image'
import {Container} from '../global';

const Header = () => (
    <StaticQuery
        query={graphql`
      query {
        pictures_smiling: file(
          sourceInstanceName: { eq: "pictures" }
          name: { eq: "smiling_toddler" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
        render={data => (
            <HeaderWrapper>
                <BackgroundImage fluid={data.pictures_smiling.childImageSharp.fluid}>
                    <Container>
                        <Grid>
                            <Text>
                            <br/>
                            <p class="homepage-cta" id="homepage-cta">
                                <StyledLink to={`#contact-info`}>
                                    Contact Us&nbsp;&#x2794;
                                </StyledLink>
                            </p>
                            </Text>
                        </Grid>
                    </Container>
                </BackgroundImage>
            </HeaderWrapper>

        )}
    />
);

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};
  padding-top: 96px;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 128px;
   
  }
`;

const Art = styled.figure`
  width: 100%;
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;
  height: 800px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;
    height: 400px;

    

    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  justify-self: center;
  text-align: right;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.color.black.regular};
  }
`;

export default Header;
