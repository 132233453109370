import React from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';
import Img from 'gatsby-image';

import CircleDivs from '../../components/common/CircleDivs';
import chemistry from '../../../static/icons/001-chemistry.png';
import bookshelf from '../../../static/icons/002-bookshelf.png';
import brain from '../../../static/icons/003-brain.png';
import apples from '../../../static/icons/006-healthy.png';
import calculator from '../../../static/icons/005-calculator.png';
import crayon from '../../../static/icons/006-crayon.png';
import art from '../../../static/icons/004-fine-arts.png';

const content2 = [
  { brief: <img src={chemistry}  alt={'twitter'}/>,
    text: "Promote a sense of curiosity through observations, experiments, and STEM programs. ",
    title: "Science"},
  { brief: <img src={bookshelf}  alt={'twitter'}/>,
    text: "Engage interests through large- and small-group activities that focus on skills cultivated through listening, speaking, reading, and writing. ",
    title: "Literacy & Language"},
  { brief: <img src={apples}  alt={'twitter'} height="128" width="128"/>,
    text: "Role-modeling a healthy living by instilling nutritional value, physical activity, and hygiene development.  ",
    title: "Wellness"},
  { brief: <img src={art}  alt={'twitter'}/>,
    text: "Encourage creativity through experiences in art, dance, and music. ",
    title: "Arts"},
  { brief: <img src={calculator}  alt={'twitter'}/>,
    text: "Incorporated throughout the day to promote critical thinking and develop a problem-solving mindset. ",
    title: "Math"},
  { brief: <img src={crayon}  alt={'twitter'}/>,
    text: "Providing a variety of tools to develop the coordination of purposeful movements. ",
    title: "Fine Motor"},
  { brief: <img src={brain}  alt={'twitter'}/>,
    text: "Focus on several interrelated areas, social interaction, emotional awareness, and self-regulation. ",
    title: "Social-Emotional"}
];

const CommunityLogos = () => (
  <StaticQuery
    query={graphql`
      query {
        drakeheadstart: file(
          sourceInstanceName: { eq: "pictures" }
          name: { eq: "DrakeHeadStart" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
          everystep: file(
            sourceInstanceName: { eq: "pictures" }
            name: { eq: "everystep" }
            ) {
              childImageSharp {
                fluid(maxWidth: 760) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }

            iowayoungchildren: file(
              sourceInstanceName: { eq: "pictures" }
              name: { eq: "iowaassocyoungchildren" }
              ) {
                childImageSharp {
                  fluid(maxWidth: 760) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }

              lionsclub: file(
                sourceInstanceName: { eq: "pictures" }
                name: { eq: "lionsclub" }
                ) {
                  childImageSharp {
                    fluid(maxWidth: 760) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }

                teachlogo: file(
                  sourceInstanceName: { eq: "pictures" }
                  name: { eq: "TeachIowaLogo" }
                  ) {
                    childImageSharp {
                      fluid(maxWidth: 760) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                    }
                  }

                  unitedway: file(
                    sourceInstanceName: { eq: "pictures" }
                    name: { eq: "unitedwaylogo" }
                    ) {
                      childImageSharp {
                        fluid(maxWidth: 760) {
                          ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                      }
                    }

                    womenunited: file(
                      sourceInstanceName: { eq: "pictures" }
                      name: { eq: "WomenUnited" }
                      ) {
                        childImageSharp {
                          fluid(maxWidth: 760) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                          }
                        }
                      }

                      drakeearlyheadstart: file(
                        sourceInstanceName: { eq: "pictures" }
                        name: { eq: "DrakeEarlyHeadStart" }
                        ) {
                          childImageSharp {
                            fluid(maxWidth: 760) {
                              ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                          }
                        }
      }
    `}
    render={data => (
      <div className="row row-cols-1 row-cols-md-4">
      <div className="col mb-4" >
        <div className="card h-60">
          <a href="https://www.drakeheadstart.org/">
          <Img fluid={data.drakeheadstart.childImageSharp.fluid} className="card-img-top"/>
          </a>
            </div>
          </div>

          <div className="col mb-4">
            <div className="card h-60">
              <a href="https://www.everystep.org/">
              <Img fluid={data.everystep.childImageSharp.fluid} className="card-img-top"/>
              </a>
            </div>
          </div>

          <div className="col mb-4">
            <div className="card h-80">
              <a href="http://www.iowaaeyc.org/">
              <Img fluid={data.iowayoungchildren.childImageSharp.fluid} className="card-img-top"/>
              </a>
            </div>
          </div>

          <div className="col mb-4">
            <div className="card h-80">
              <a href="https://www.iowalions.org/">
              <Img fluid={data.lionsclub.childImageSharp.fluid} className="card-img-top"/>
              </a>
            </div>
          </div>

          <div className="col mb-4">
            <div className="card h-80">
              <a href="http://www.iowaaeyc.org/teach.cfm">
              <Img fluid={data.teachlogo.childImageSharp.fluid} className="card-img-top"/>
               </a>
            </div>
          </div>

          <div className="col mb-4">
            <div className="card h-80">
              <a href="https://www.unitedwaydm.org/">
              <Img fluid={data.unitedway.childImageSharp.fluid} className="card-img-top"/>
              </a>
            </div>
          </div>

          <div className="col mb-4">
            <div className="card h-80">
              <a href="https://www.unitedwaydm.org/women-united">
              <Img fluid={data.womenunited.childImageSharp.fluid} className="card-img-top"/>
              </a>
            </div>
          </div>

          <div className="col mb-4">
            <div className="card h-80">
              <a href="https://www.drakeheadstart.org/about-drake-university-head-start/early-head-start/">
              <Img fluid={data.drakeearlyheadstart.childImageSharp.fluid} className="card-img-top"/>
              </a>
            </div>
          </div>
         
        </div>
    )}
    />
    );


const HompageMainContent = () => {
  return (
    <div class="home-main-content">
        <div class="section-row">
            <h1 id="about">About Us</h1>
            <p>
                Welcome to Wonder Years Academy! We have been servicing Des Moines since
                1992. Providing quality education to children in our community.
                Partnering with organizations to ensure the needs of all of our staff
                and families are being met. Our goal is to provide an environment where
                every child has the opportunity to succeed.
            </p>
        </div>
        <div className="section-row">
            <h1>Curriculum Overview</h1>
              <p>
                At our center, we utilize multiple comprehensive, research-based
                curriculums that promote exploration through play as a way of learning.
                It encourages children to develop confidence, creativity, and life-long
                critical thinking skills.{' '}
              </p>
              <CircleDivs items={content2}/>
        </div>

        <div name="contact-info" className="section-row two-col">
            <div class="col-md-6 col-xs-12">
                <p>3838 Martin Luther King Jr Pkwy<br/>
                    Des Moines, Iowa 50310</p><br/>
                <p>Monday - Friday 6:30 am - 5:30 pm </p>
                <a href="tel:515-255-6184" className="wya-link">(515) 255-6184</a><br/>
                <p>
                    <i className="fab fa-facebook"></i>
                </p>
            </div>
            <div class="col-md-6 col-xs-12">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2982.1610320945206!2d-93.65409638426199!3d41.63065057924263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87ee9be52a7e61b5%3A0x2e982358c6c83750!2s3838%20Martin%20Luther%20King%20Jr%20Pkwy%2C%20Des%20Moines%2C%20IA%2050310!5e0!3m2!1sen!2sus!4v1583609129401!5m2!1sen!2sus"
                    width="95%" height="450" frameBorder="0" allowFullScreen=""></iframe>
            </div>
        </div>
        <div className="section-row">
            <h1>Community Partners</h1>
            {CommunityLogos({})}
        </div>
    </div>
  );
};

export default HompageMainContent;
